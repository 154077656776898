export class Utils {
// ** traverses json keys - nesting ok  **/

  static jsonTraverse(obj: any, fn: (obj: any, prop: any, scope?: any) => void) {
    for (const key in obj) {
      fn.apply(obj, [key, obj[key], obj]);
      if (obj[key] !== null && typeof(obj[key]) === 'object') {
        Utils.jsonTraverse(obj[key], fn);
      }
    }
  }

  // ** get status of a survey answer. probably move to its own class  **/
  static getProgressOfAnswer(answer: any): any {
    const progress: any = {total: 0, answered: 0};
    Utils.jsonTraverse(answer, (key, value, scope) => {
      if (key === 'type' && value === 'QuestionOption') {
        progress.total++;
        if (scope.selected) {
          progress.answered++;
        }
      }
    });
    return progress;
  }


  static getGaugeClassRange(val: number): string {
    if (val >= 0 && val <= 0.2) {
      return 'very-low';
    } else if (val > 0.2 && val <= 0.4) {
      return 'low';
    } else if (val > 0.4 && val <= 0.54) {
      return 'medium';
    } else if (val > 0.54 && val <= 0.7) {
      return 'high';
    } else {
      return 'very-high';
    }
  }

}

