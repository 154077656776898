<div class="content splash">
  <div class="splash-message">
    <alert></alert>

    <h2>SRAM Rules of Behavior</h2>
    <h3>1.0 Principles and Responsibilities</h3>
    <p>Personnel who use SRAM federal computing resources to process, store, or transmit unclassified data and/or information shall first read and familiarize themselves with the principles and responsibilities discussed below and sign the attached Computer Use and Security Agreement, a signed copy of which may be retained by the user, and the original by the site. </p>
    <h3>2.0 For Official Approved Use Only</h3>
    <p>Department of Energy (DOE) computing resources are government property funded by the Department of Energy for the purpose of supporting the various programmatic and scientific research efforts needed to accomplish the Department's missions. As such, these resources are to be used only for official government business. Users should remember that when they use their organization's computing resources, they are acting in their employment capacity on behalf of their employer and the Department of Energy. </p>
    <p>If allowed, e-mail that you send and receive through the site's computational resources is official business by definition; there ordinarily would be no legitimate reason to use anonymous re-mailers or personally owned copies of encryption software for the transmission of your messages. Unless approved by management, any activity outside the scope of your employment, or any activity that could embarrass the organization must be avoided. </p>
    <h3>3.0 Monitoring, Recording, and Auditing of Federal Computing Resources</h3>
    <p>Because these computational resources are government property, their use may be subject to monitoring, recording, and audits to insure the systems and networks are functioning properly, to protect against unauthorized access or use, and to ensure the confidentiality and integrity of data and information resident on the systems and networks. In addition, DOE may access any user's federally provided computer system or data communications and disclose
      information obtained through such auditing to appropriate third parties, including law enforcement authorities. Users have NO EXPECTATION OF PRIVACY when using federal computing resources. Use federal computing resources and network connections constitutes CONSENT by the user to monitoring, recording, and auditing for purposes identified above.
    </p>
    <h3>4.0 Encryption of Unclassified Data and Information </h3>
    <p>Any data and/or information (classified or unclassified) in any state (in use, in storage, in transmission) may be encrypted only with prior authorization of the ISSM and subject to such conditions and guidance as established by DOE. Prior to using encryption, the ISSM or designated employee must have a process in place to acquire the decryption key after proper authorization to ensure that encrypted information on the systems will be available in the event the user is no longer available to decrypt the information.</p>
    <h3>5.0 Manager/Supervisor Responsibilities </h3>
    <p>All management and supervisory personnel must be aware of, and be leaders in applying these principles and carrying out these responsibilities. Supervisors are responsible for implementing these principles in their organization and will be accountable for ensuring that users are aware of, and acknowledge their responsibilities.</p>
    <h3>6.0 Violations</h3>
    <p>Acceptance of, and adherence to, these principles and responsibilities regarding appropriate use by all users of federal computational resources is very important. Irresponsible conduct resulting in violations of these principles and site policy may lead to loss of system privileges and/or disciplinary action, up to and including termination of employment, as well as criminal penalties. </p>
    <h3>7.0 Computer Use and Security Agreement</h3>
    <p>I have read and understand the SRAM Rules of Behavior to which this Computer Use and Security Agreement is attached. I agree that users of DOE computers, networks and information systems (IS) are an integral part or the overall DOE Information Assurance program. I also understand that this grant of access to DOE computing resources indicates a level of trust bestowed upon me by my management and the Department of Energy. I agree that I am responsible for my actions, and I am aware of and acknowledge the following Accepted Users Principles: </p>
    <h3></h3>
    <p>At a minimum, I am responsible for abiding by these principles: </p>
    <ul>
      <li>Ensuring that federal computing resources are used only for official government business. Any other use must be approved in writing by my line manager.</li>
      <li>Knowing who my site computer security personnel are and how they can be contacted. (See the attached document for contact information).</li>
      <li>Knowing the level of sensitivity of the information processed on my federal computing resource (e.g., nonsensitive unclassified, sensitive unclassified, or classified).</li>
      <li>Ensuring that all software I use is being used in compliance with applicable licensing agreements and has been authorized for use by my line manager.</li>
      <li>Protecting the information I am processing from access by, or disclosure to, unauthorized personnel.</li>
      <li>Immediately reporting all security incidents and potential threats and vulnerabilities involving federal computing resources to the designated computer security personnel.</li>
      <li>Protecting my authenticators, such as passwords or smartcards.</li>
      <li>Reporting any compromise or suspected compromise of a password to the designated computer security personnel.</li>
      <li>Accessing only systems, networks, data, control information, and software for which I am authorized.</li>
      <li>Ensuring that system media and system output is marked according to site/system requirements and is properly controlled and stored.</li>
      <li>Informing management when access to a particular federal computing resource is no longer required, such as when I complete a project, transfer to another position, retire, resign from employment, etc.</li>
      <li>Avoiding the introduction of malicious code (e.g., viruses, worms, Trojan horses) into any computing resource.</li>
      <li>Notifying your SRAM Site Administrator when access to SRAM is no longer required.</li>
      <li>Obtaining management approval and notifying other appropriate personnel (e.g., property management, inventory control, computer security) before relocating any federal computing resources.</li>
      <li>Users shall not post any government information on public web sites and via social media.</li>
      <li>Social media shall not be used to share or communicate official government business.</li>
    </ul>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div>
        <mat-card>
          <mat-radio-group formControlName="isAdmin">
            <mat-radio-button value="0">I am a SRAM Site Administrator</mat-radio-button>
            <mat-radio-button value="1">I am not a SRAM Site Administrator</mat-radio-button>
            <mat-error *ngIf="submitted && f.isAdmin.errors?.required">
              Selecton is <strong>required</strong>
            </mat-error>
          </mat-radio-group>
        </mat-card>
        <br/>
        <p>
          If I am a SRAM Site Administrator, I understand I am also responsible for the following principles.
        </p>
      </div>
      <ul>
        <li>Understand that it is their responsibility to comply with all security measures necessary to prevent the unauthorized disclosure, modification, or destruction of information; follow appropriate system security policies, guidelines and procedures.</li>
        <li>Grant read or write authority no higher than is granted to him/her (e.g., a component level user administrator shall not assign department level access to another user administrator).</li>
        <li>Access application programs only for the purpose of creating or maintaining files.</li>
        <li>Not make modifications to system configurations that could impact availability or security of the system
        without the approval of the Change Control Board and/or change management process.</li>
        <li>Not perform general user activities under the same account (user name and password) due to the security
        requirement for separation of duties.</li>
        <li>Protect all passwords from unauthorized disclosure and not share accounts with another SRAM Site
        Administrator.</li>
        <li>Make the system available at any time to the ISSO for inspection and review of audit logs.</li>
        <li>Make the computer(s) available for periodic reviews of the security configuration by independent testers.</li>
        <li>Make changes to system configuration as directed to meet Vulnerability and Patch Management requirements.</li>
        <li>Immediately record and report any security incidents to the ISSO.</li>
        <li>Notify the System Owner, ISSO, and other SRAM Site Administrators when access to SRAM is no longer
        required.</li>
        <li>SRAM Site Administrators must complete an annual review of the SRAM user account list with the System
        Owner and verify that active users for their site still require access.</li>
      </ul>
      <mat-card>
        <mat-card-content>
          <h2>To be completed by user</h2>
          <section>
            <mat-checkbox formControlName="readRules">
              By Checking this box, I have read the Rules or Behavior and (print full name)
              understand my responsibilities as a user of federal computing resources.
            </mat-checkbox>
            <mat-error *ngIf="submitted && f.isAdmin.errors?.required">
              Selecton is <strong>required</strong>
            </mat-error>
          </section>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <h2>To be completed by user</h2>
          <section>
            <mat-checkbox formControlName="agreeRules">
              By Checking this box, I certify that the above named employee has read and signed the Rules of Behavior..
            </mat-checkbox>
            <mat-error *ngIf="submitted && f.isAdmin.errors?.required">
              Selecton is <strong>required</strong>
            </mat-error>
          </section>
        </mat-card-content>
        <button mat-flat-button color="primary">Agree</button>
      </mat-card>
      <div class="splash-decision">
      </div>
  </form>
  </div>
</div>

