// Report definition pulled from API
export class Report {
    constructor(fields: any) {
        for (const f in fields) {
            this[f] = fields[f];
        }
    }
}

export interface Report {
    [prop: string]: any;
}

// implemented report.
export class ReportImpl {
    description: Report;
    id: number;
    code: string;
    name: string;
    rows: any[];
    display: string;
    context: string;
    ranges: any[];
    visible: boolean;
    cssclass: string;
    levels: number;

    constructor(report?: Report) {
        this.rows = [];
        if (report) {
            this.description = report;
            this.id = report.id;
            this.code = report.code;
            this.context = report.context;
            this.name = report.name;
            this.ranges = report.meta.ranges;
            this.display = report.display;
            if (report.display.indexOf('Chart') > -1) {
                this.display = report.meta.chart_type;
            }
            this.visible = true; //default
            this.cssclass = report.meta.cssclass;
            this.levels = report.meta.levels;
        }
    }

}
import {Answer} from "./models"
export class ReportReview {
    survey_answer_id: number;
    signature: string;
    classification_id: number;
    classification_name: string;
    classification_admonishment: string; // for reference
    guidance_id: number;
    guidance_name: string;
    review_date: string;

    constructor(answer: Answer) {
        this.survey_answer_id = answer.id;
        this.classification_id = answer.classification_id;
        this.guidance_id = answer.guidance_id;
    }
}
