<button title ="V{{buildNumber}}" class="btn-decoration" type="button" (click)="popup()">
 V{{buildNumber}}
</button>

<ng-template #versionDialog>
  <div class="flex-bar">
    <h4 matDialogTitle>
      Current Software Release: SRAM V{{buildNumber}}
    </h4>
    <button mat-icon-button (click)="closeVersionDialog()" aria-label="Close" tabindex="-1"><mat-icon>close</mat-icon></button>
  </div>
  <div mat-dialog-content>

    <div *ngFor="let version of versions$ | async">
      <h4 class="version-title">V{{version.version}}</h4>

      <div [innerHTML]="version.notes" class="notes"></div>
    </div>

  </div>
</ng-template>





