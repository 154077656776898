
export class Folder {
  id: number;
  organization_id: number;
  name: string;
  type: string;
  description: string;
  depth: number;
  parent_id: number;
  folders: Folder[];
  editMode?: boolean; // local use

  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }

}
