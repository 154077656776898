import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AccountService } from '@app/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.accountService.userValue;
        const isLoggedIn = this.accountService.isLoggedIn();
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }

        // return next.handle(request);
        return next.handle(request).pipe(map(response => {
          if (response instanceof HttpResponse && isLoggedIn) {
            // get headers & repopulate token
            const tokenStr = response.headers.get('X-Token');
            const expStr = response.headers.get('X-Token-Exp');
            if (tokenStr ) {
              this.accountService.updateToken({token: tokenStr, exp: expStr});
            }
          }
          return response;
        }));
    }
}

// ${user.token}
