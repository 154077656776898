import { Injectable } from "@angular/core";
import { Adapter } from './adapter';


export class Answer {
    constructor(fields: any) {
        for (const f in fields) {
            this[f] = fields[f];
        }
    }

    public get isFinal(): boolean {
        return (this['status'] === 'final');
    }
}
export interface Answer {
    [prop: string]: any;
}

export class Scenario {
    id: number;
    nick: string;
    name: string;
    parent: string;
    constructor(id: number, nick: string, name: string, parent: string) {
        this.id = id;
        this.nick = nick;
        this.name = name;
        this.parent = parent;
    }
}

@Injectable({
    providedIn: 'root',
})
export class ScenarioAdapter implements Adapter<Scenario> {
    adapt(item: any): Scenario {
        return new Scenario(item.id, item.nick, item.name, item.parent);
    }
}
