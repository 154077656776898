import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { AccountService } from '@app/_services';
import { environment } from '@env/environment';
import { Observable, Subscription } from 'rxjs';
import { User } from '@app/_models';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    user$: Observable<User>;
    apiUrl = environment.apiUrl;
    isProd = environment.production;
    @ViewChild('chatModal') chatModal: TemplateRef<any>;

    public dialogRef: MatDialogRef<TemplateRef<any>>;

    constructor(private accountService: AccountService, public dialog: MatDialog) { }

    ngOnInit(): void {
      this.user$ = this.accountService.user;
    }

    isLoggedIn(): boolean {
      return this.accountService.isLoggedIn();
    }

    isOrgAdmin(u: User): boolean {
      return u.organizations.filter(o => o.role === 'admin' ).length > 0;
    }

    canAdmin(u: User): boolean {
      return (u.site_role === 'admin' || this.isOrgAdmin(u));
    }

    logout(): void {
        this.accountService.logout();
    }
}
