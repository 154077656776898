import { Component, ElementRef, TemplateRef, ViewChild, OnChanges, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Note, User } from '@app/_models';
import { NoteService, AlertService, AccountService } from '@app/_services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'notes',
    templateUrl: './notes.component.html',
    styleUrls: ['../app.component.scss']
})
export class NotesComponent implements OnChanges {
    @ViewChild('scrollbox') notesDiv: ElementRef;

    user: User;
    note: Note;
    notes: Note[];
    subscription: Subscription;

    public dialogRef: MatDialogRef<TemplateRef<any>>;
    @ViewChild('notesModal') notesModal: TemplateRef<any>;

    @Input() subject: any;
    @Input() ref: any;
    @Input() currentLayer: number
    @Input() noteLabel: string

    constructor(
        private accountService: AccountService,
        public dialog: MatDialog,
        private noteService: NoteService,
        private alertService: AlertService) {
            this.user = this.accountService.userValue;
            this.notes = [];
    }

    ngOnChanges(): void {
        this.resetNote();

        let layerParams = {}
        if (this.currentLayer != null)
            layerParams = { layer: this.currentLayer }

        this.subscription = this.noteService.getNotes(this.subject, this.ref, layerParams)
            .subscribe(s => {
                if(s) {
                    this.notes = s as Note[];

                    this._scrollToBottom();
                }
            });
    }
    ngAfterViewChecked() {
        this._scrollToBottom();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    //TODO: Add form validation
    viewNotes(): void {
        this.dialogRef = this.dialog.open(this.notesModal, {width: '800px'});

        this.dialogRef.afterClosed().subscribe(result => {
            if(!result) {
                this.resetNote();
            }
        });
    }
    onDialogClose(): void {
        this.dialogRef.close();
    }


    resetNote(): void {
        this.note = { subject_id: this.subject.id, subject_type: this.subject.type };

        if (this.ref) {
            this.note.ref_id = this.ref.id;
            this.note.ref_type = this.ref.type;
        }
    }

    isInEdit(id: number): boolean {
        return id == this.note.id;
    }

    editNote(note: Note): void {
        this.note = note;
    }

    cancelEdit(): void {
        this.resetNote();
        this.onDialogClose();
    }

    deleteNote(idx: number): void {
        if (confirm("Are you sure to delete this note?")) {
            this.noteService.deleteNote(this.notes[idx].id).subscribe(() => 'Delete successful');
            this.notes.splice(idx, 1);
            this.resetNote();
        }
    }

    saveNote(): void {
        var isNew = (this.note.id) ? false : true;

        if (isNew) {

            if (!!this.currentLayer)
                this.note.layer = this.currentLayer;

            if (!!this.noteLabel)
                this.note.label = this.noteLabel;

        }

        this.subscription = this.noteService.saveNote(this.note)
            .subscribe(res => {
                if (isNew) {
                    this.notes.push(res);
                }
                this.resetNote();
            },
                err => this.alertService.error(err)
            );
    }

    _scrollToBottom(): void {
        try {
            this.notesDiv.nativeElement.scrollTop = this.notesDiv.nativeElement.scrollHeight;
        } catch (err) { }
    }

}
