import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Api } from './api.service';
import { Note } from '@app/_models';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    constructor(private api: Api) {

    }

    /*
     * subject: the subject to which the note is attached
     */
    getNotes(subject: any, ref?: any, params?: any): Observable<Note[]> {
        if (!params) { params = {}; }
        params.subject_id = subject.id;
        params.subject_type = subject.type;
        if (ref) {
            params.ref_id = ref.id;
            params.ref_type = ref.type;
        }
        return this.api.get('notes', params);
    }

    saveNote(note: Note): Observable<Note> {
        if (note.id) {
            return this.api.put('notes/' + note.id, note);
        } else {
            return this.api.post('notes', note);
        }
    }

    deleteNote(id: number): Observable<any> {
        return this.api.delete('notes/' + id);
    }

}
