import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { Task, TaskType } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private api: Api) { }

  getTaskTypes(): Observable<TaskType[]> {
    return this.api.get('admin/task_types');
  }

  getTasks(): Observable<Task[]> {
    return this.api.get('admin/tasks');
  }

  saveTask(task: Task): Observable<Task> {
    return this.api.post('admin/tasks',  {task});
  }

  updateTask(task: Task): Observable<Task> {
    return this.api.put('admin/tasks/' + task.id,  {task});
  }

  deleteTask(task: Task): Observable<Task> {
    return this.api.delete('admin/tasks/' + task.id);
  }
}
