<button mat-button color="primary" (click)="viewNotes()" [disabled]="user.organization?.role=='reader'">
    Additional Information
</button>

<ng-template #notesModal>
    <div class="flex-bar">
        <h4 matDialogTitle>Additional Information</h4>
        <button mat-icon-button (click)="onDialogClose()" aria-label="Close" tabindex="-1"><mat-icon>close</mat-icon></button>
    </div>
    <div mat-dialog-content>
        <div class="scrollbox" *ngIf="notes?.length > 0" #scrollbox>
            <div *ngFor="let lnote of notes; let idx = index;" >
                <div class="note-item" *ngIf="!isInEdit(lnote.id)">
                    <h4>{{ lnote.label }}</h4>
                    
                    <span class="float-right">
                        <i class="far fa-edit f-pad f-action" (click)="editNote(lnote)" aria-label="Edit Note"></i>
                        <i class="far fa-trash-alt f-action" (click)="deleteNote(idx)" aria-label="Delete Note"></i>
                    </span>

                    <p class="mb-0">{{lnote.body}}</p>
                    
                    <small><i>Posted on {{lnote.created | date}} by {{lnote.user.name}}.</i></small>
                    
                    <span class="private-tag" *ngIf="lnote.is_private">Private</span>
                </div>
                <!--div *ngIf="isInEdit(lnote.id)">
                    <ng-container *ngTemplateOutlet="editBlock"></ng-container>
                </div-->
            </div>
        </div>
        <!--div *ngIf="!note.id">
            <ng-container *ngTemplateOutlet="editBlock"></ng-container>
        </div-->

        <alert></alert>
        <mat-form-field appearance="fill" class="input-full-w">
            <mat-label *ngIf="!note.id">Add a new note</mat-label>
            <textarea matInput id="body" [(ngModel)]="note.body"></textarea>
        </mat-form-field>
    
        <div class="flex-bar">
            <mat-checkbox [(ngModel)]="note.is_private" id="isPrivate">Keep note private</mat-checkbox>
            <div>
                <button mat-flat-button (click)="cancelEdit()" aria-label="Cancel">Cancel</button>
                <button mat-flat-button color="primary" (click)="saveNote()" aria-label="Save" [disabled]="!note.body || note.body == ''">Save</button>
            </div>
        </div>

    

    </div>
    
</ng-template>
