import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Survey } from '@app/_models';
import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
    surveyId: number;
    private surveyBS = new BehaviorSubject<Survey>(null);
    survey = this.surveyBS.asObservable();

    constructor(public api: Api) {

    }

    query(params?: any): Observable<Survey[]> {
      return this.api.get('surveys', params);
    }

    // gets the answer.
    getSurvey(id: any, params?: any): Observable<Survey> {
        this.surveyId = id;
        return this.api.get('surveys/' + id, params).pipe(
            tap(res => {
              this.surveyBS.next(res);
            }),
            map(res => res)
        );
    }

    add(copyFrom?: number): Observable<Survey> {
        let params = {};
        if (copyFrom) {
            params = {copy_survey: copyFrom};
        }
        return this.api.post('surveys', params).pipe(
            tap(res => this.surveyBS.next(res)),
            map(res => res)
        );
    }

    /** Send only name and version. Whole survey will not process */
    updateSurvey(survey: Survey): Observable<Survey> {
        return this.api.put('surveys/' + survey.id, survey);
    }

    addAssetType(at: any): Observable<any> {
        return this.api.post('surveys/' + this.surveyId + '/asset_types/', at);
    }

    editAssetType(at: any): Observable<any> {
        return this.api.put('surveys/' + this.surveyId + '/asset_types/' + at.id, at);
    }
}
