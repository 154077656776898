export class TaskType {
  id: number;
  name: string;
  active: boolean;

  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }
}

export class Task {
  id: number;
  name: string;
  task_type_id: number;
  default_duration: number;
  active: boolean;

  constructor(fields: any) {
    for (const f in fields) {
      this[f] = fields[f];
    }
  }
}


