import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      let isAuth = false;
      if (this.accountService.isLoggedIn()) {
        const user = this.accountService.userValue;
        if (user && (user.site_role === 'admin' || user.organization.role === 'admin')) {
          // authorised so return true
          isAuth = true;
        }
      }
      if (isAuth) {
        return true;
      } else {
        // not authorized in so redirect to main
        this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
        return false;
      }
    }
}
