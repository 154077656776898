import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@app/_models/';
import { Api } from './api.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private api: Api
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    // need to check this for security
    public get userValue(): User {
      if (this.userSubject.value) {
        return this.userSubject.value;
      } else {
        const u = JSON.parse(localStorage.getItem('rtuser'));
        if (u) {
          this.userSubject.next(u);
          return u;
        } else {
          return null;
        }
      }
    }

    login(username: string, password: string): Observable<User> {
      const opts = {email: username, password};
      return this.api.login(opts)
            .pipe(map((user: User) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('rtuser', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    loginWithToken(apiToken: string): Observable<User> {
        // if (this.userValue && this.userValue.token == apiToken) {
        //     return this.user; // maybe should renew
        // } else {
            const opts = {api_token: apiToken};
            return this.api.login(opts)
                .pipe(map((u: User) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('rtuser', JSON.stringify(u));
                    this.userSubject.next(u);
                    return u;
            }));
        // }
    }

    logout(sessionExpired: boolean = false): void {
        // remove user from local storage and set current user to null
        localStorage.removeItem('rtuser');

        // remove answer filters upon logout
        localStorage.removeItem('answersPerPage');
        localStorage.removeItem('answerStatus');
        localStorage.removeItem('answerOrg');

        if (sessionExpired) {
          localStorage.setItem('sessionExpired', 'true');
        }
        this.userSubject.next(null);
        this.router.navigate(['/']);
    }

    updateToken(tokenJson: any): void {
      if (this.userValue) {
        const updatedUser = Object.assign(this.userValue);
        updatedUser.token = tokenJson.token;
        updatedUser.exp = tokenJson.exp;
        localStorage.setItem('rtuser', JSON.stringify(updatedUser));
        this.userSubject.next(updatedUser);
      }
    }

   update(user: User): Observable<User> {
        return this.api.put('users/' + user.id, {user})
            .pipe(map(xuser => {
                // update stored - if self
                if (user.id === this.userValue.id) {
                    // update local storage
                    const updatedUser = { ...this.userValue, ...xuser };
                    localStorage.setItem('rtuser', JSON.stringify(updatedUser));
                    // publish updated user to subscribers
                    this.userSubject.next(updatedUser);
                }
                return xuser;
            }));
    }

    isLoggedIn(): boolean {
      if (this.userValue && this.userValue.token && this.userValue.exp) {
        // future date of exp
        const expDate = this.userValue.exp;
        const now = new Date();
        const utcDate = new Date(now.getTime() + (now.getTimezoneOffset() * 60 * 1000));
        return new Date(expDate) >= utcDate;
      } else {
        return false;
      }
    }

}
