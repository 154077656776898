import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Api } from './api.service';
import { Organization } from '@app/_models'

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    constructor(private api: Api) {

    }

    /*
     * subject: the subject to which the note is attached
     */
    getOrganizations(): Observable<Organization[]> {
        return this.api.get('organizations');
    }

}