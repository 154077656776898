import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Version} from '@app/_models';
import {Observable} from 'rxjs';
import {VersionService} from '@app/_services';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AccountService} from '@app/_services';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  loggednIn = false;
  versions$: Observable<Version[]>;
  buildNumber: string;

  public dialogRef: MatDialogRef<TemplateRef<any>>;
  @ViewChild('versionDialog') versionDialog: TemplateRef<any>;


  constructor(private versionService: VersionService,
              private accountService: AccountService,
              public dialog: MatDialog) {
    this.versions$ = this.versionService.getVersions();
  }

  ngOnInit(): void {
    this.versions$.subscribe((value) => {
      if (value) {
        this.buildNumber = value[0].version;
      }
    });
  }

  popup(): void {
    if (this.isLoggedIn()){
      this.dialogRef = this.dialog.open(this.versionDialog, {width: '50%'});
    }
  }

  isLoggedIn(): boolean {
    return this.accountService.isLoggedIn();
  }

  closeVersionDialog(): void {
    this.dialogRef.close();
  }
}

