import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private accountService: AccountService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.accountService.isLoggedIn()) { // authorized so return true
          console.log("logged in")
          return true;
        }

        const token = route.queryParams["apiToken"];
        if(token) {
          console.log("apiToken from url", token)

          this.accountService.loginWithToken(token).pipe(first())
                .subscribe({
                    next: () => {
                        this.router.navigateByUrl(state.url);
                        return true;
                    },
                    error: error => {
                        this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
                        return false;
                    }
                });
        } else {
          console.log("NO TOKEN, not logged in")
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }
}
