import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'sram-app';
    asPdf:boolean = false;

    constructor(private route: ActivatedRoute) {
        route.queryParams.subscribe(params => {
            this.asPdf = (params["pdf"]) ? true : false;
        });
    }

}
