<div *ngIf="(answer$ | async) as answer">
    <div class="d-flex">
        <div class="flex-grow-1">
            <h1 class="mt-0">{{answer.name}}</h1>
        </div>
    </div>

    <small class="sub-h1-header"><i>Created {{answer.created | date}} by {{answer.user.name}}.</i></small>


</div>