import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Api } from './api.service';
import { Scenario, ScenarioAdapter } from '@app/_models';

@Injectable({
    providedIn: 'root'
})
export class ScenarioService {
    scenariosListBS = new BehaviorSubject<Scenario[]>([]);
    private surveyId: number;
    private currentScenarioBS = new BehaviorSubject<Scenario>(new Scenario(0, null, null, null) );
    currentScenario = this.currentScenarioBS.asObservable();
    scenarioApplies = new BehaviorSubject<boolean>(false);
    constructor(private api: Api, private adapter: ScenarioAdapter) {

    }

    getScenarioApplies(): Observable<boolean> {
        return this.scenarioApplies.asObservable();
    }
    setScenarioApplies(applies: boolean): void {
        this.scenarioApplies.next(applies);
    }

    public get getScenario(): Scenario {
        return this.currentScenarioBS.value;
    }

    public get scenarios(): Scenario[] {
        return this.scenariosListBS.value;
    }

    public getScenarioName(nick: string): string {
      const scenario = this.scenariosListBS.value.filter(s => s.nick === nick)[0];
      return scenario?.name;
    }

    getScenarios(surveyId: number): Observable<any> {
        if (this.surveyId === surveyId && this.scenariosListBS.value.length > 0) {
            return of(this.scenariosListBS.value);
        } else {
            return this.fetchScenarios(surveyId);
        }
    }

    setScenario(s: Scenario): void {
      this.currentScenarioBS.next(s);
    }

    findAndSetScenario(nick: string): void {
      const scenario = this.scenariosListBS.value.filter(s => s.nick === nick)[0];
      this.setScenario(scenario);
    }

    private fetchScenarios(surveyId: number): Observable<any> {
      this.surveyId = surveyId;
      return this.api.get('scenarios', {survey_id: surveyId}).pipe(
        tap(res => {
            this.scenariosListBS.next(res);
        }),
        map(res => res)
      );
    }

}
