import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AccountService, AlertService } from '@app/_services';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    declined = false;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private alertService: AlertService,
                private accountService: AccountService)
    {
      let token = null;
      // is it me or does an observable feel like overkill here? Ugh
      this.route.queryParams.subscribe(params => {
          token = params.apiToken;

          if (token) {
              this.accountService.loginWithToken(token).subscribe({
                  next: () => {
                    this.router.navigate(['/answers']);
                  },
                  error: error => {
                    this.alertService.error('Invalid Token');
                  }
              });
          }
      });
    }

    ngOnInit(): void {
      const sessionExpired = localStorage.getItem('sessionExpired') === 'true';

      setTimeout(() => {
        if (sessionExpired) {
          this.alertService.warn('Your session has expired after 15 minutes.');

          localStorage.removeItem('sessionExpired');
        }
      }, 1000);

    }

    accept(): void {
      if (environment.production) {
        window.location.href = `${environment.apiUrl}saml/login`;
      } else {
        this.router.navigate(['/account/login']);
      }
    }

    decline(): void {
      // ensure logged out
      this.declined = true;
    }

}
