import { async } from 'rxjs';

export class AnswerAnalyzer {

    answerJson: any;
    scenario: string;
    private calculations: any = {}; // calculations to be solved
    private values: any = {}; // calculations solved
    private maxLayers = 1;

    // for building calcuations, a scope should be provided
    // it is assumed for now that scopes are scenario NICKs
    constructor(answerJson: any, scenario: string) {
        this.answerJson = answerJson;
        this.scenario = scenario;
    }

    getValues(): any {
      return this.getAnswer().then(res => {
          return this.values;
      });
    }

    getAnswer = async (): Promise<any> => {
        this.values = {};
        this.calculations = {};
        await this.basicCalcs();
        let cnt = 0;
        while (cnt < 100 && Object.keys(this.calculations).length > 0) {
            await Analyzer.loopCalculations(this.calculations, this.values);
            // console.log("length", Object.keys(this.calculations).length, Object.keys(this.values).length)
            // console.log("values", this.values)
            cnt++;
        }
        return this.answerJson;
    }

    /* run through simple calculations that don't require other field values */
    basicCalcs = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            Analyzer._traverse(this.answerJson, (key, value, scope) => {
                if (key === 'number_layers') { this.maxLayers = value; }
                if (key === 'calculations') {
                    // if (scope.type !== 'AssetType' || scope.selected == true) { //only calculate asset types if they are selected
                        for (const ckey in value) {
                            // zero out the value to begin with since it will hold previous values
                            delete value[ckey].value;

                            if (value[ckey].formula === 'MAX') {
                                Analyzer.calculateMax(value[ckey], scope, this.scenario).then(res => {
                                    value[ckey].value = res;
                                    this.values[value[ckey].uid] = res;
                                });
                            } else if (value[ckey].formula === 'MAX_ACTUAL') {
                                Analyzer.calculateMaxActual(value[ckey], scope, this.scenario).then(res => {
                                    value[ckey].value = res;
                                    this.values[value[ckey].uid] = res;
                                });
                            } else if (value[ckey].formula === 'ACTUAL') {
                                Analyzer.calculateActual(value[ckey], scope, this.scenario).then(res => {
                                    value[ckey].value = res;
                                    this.values[value[ckey].uid] = res;
                                });
                            } else if (value[ckey].formula.match(/^PROP\(/)) {
                                const prop = value[ckey].formula.substring(6, value[ckey].formula.length - 2);
                                const v = (scope.selected) ? scope[prop] : 0;
                                value[ckey].value = v;
                                this.values[value[ckey].uid] = v;
                            } else if (value[ckey].formula.match(/^MAXPROP\(/)) {
                                const prop = value[ckey].formula.substring(9, value[ckey].formula.length - 2);  // assumptions here
                                Analyzer.calculateMaxProp(prop, value[ckey], scope).then(res => {
                                    value[ckey].value = res;
                                    this.values[value[ckey].uid] = res;
                                });
                            }
                            else if (value[ckey].formula.match(/^MINPROP\(/)) {
                              const prop = value[ckey].formula.substring(9, value[ckey].formula.length - 2);  // assumptions here
                              Analyzer.calculateMinProp(prop, value[ckey], scope).then(res => {
                                value[ckey].value = res;
                                this.values[value[ckey].uid] = res;
                              });
                            }else {
                                // calculate only if it's part of the scenario
                                if (!this.scenario || !scope.scenarios || scope.scenarios.includes(this.scenario)) {
                                    let f = value[ckey].formula;
                                    if (f.match(/MAXLAYER/gi)) {
                                        f = f.replace(/MAXLAYER/gi, this.maxLayers);
                                    }
                                    if (scope.layer > 0 && f.match(/LAYER/gi)) {
                                        f = f.replace(/LAYER/gi, scope.layer);
                                    }
                                    if (f.match(/SCENARIO/gi)) {
                                        f = f.replace(/SCENARIO/gi, this.scenario);
                                    }

                                    value[ckey].temp = f;
                                    this.calculations[value[ckey].uid] = {calc: value[ckey],
                                                                        ref: scope};
                                } else {
                                    value[ckey].value = 'N/A';
                                    this.values[value[ckey].uid] = 'N/A';
                                }
                            }
                        }
                    // }
                }
            });
            return resolve(this.answerJson);
        });
    }

}

export class Analyzer {

    /** calculates max possible value for a question  **/
    static calculateMaxProp = async (prop: string, c: any, q: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let val = 0;
            Analyzer._traverse(q, (key, value, scope) => {
                if (key === prop && value > val && scope.selected) {
                    val = value;
                }
            });
            c.value = val;
            return resolve(val);
        });
    }

    // calculates min possible value for a question  **/
    static calculateMinProp = async (prop: string, c: any, q: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      let val = 1000;
      Analyzer._traverse(q, (key, value, scope) => {
        if (key === prop && value < val && scope.selected) {
          val = value;
        }
      });
      c.value = val;
      return resolve(val);
    });
  }

    /** calculates max possible value for a question  **/
    static calculateMax = async (c: any, q: any, scenario: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            const vals: any = {0: 0};
            let rval = 0;
            if (q.question_options) {
                q.question_options.forEach((opt: any) => {
                    if (opt.scenarios.includes(scenario)) {
                        if ((typeof(opt.alternate_group) === 'undefined') || opt.alternate_group === null) {
                            vals[0] = vals[0] + opt.value;
                        } else {
                            if (!vals[opt.alternate_group] || opt.value > vals[opt.alternate_group]) {
                                vals[opt.alternate_group] = opt.value;
                            }
                        }
                    }
                });
            }
            for (const key in vals) {
                rval += vals[key];
            }
            c.value = rval;
            return resolve(rval);
        });
    }

    /** calculates actual value selected for a question **/
    static calculateActual = async (c: any, q: any, scenario: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            const vals: any = {0: 0};
            let rval = 0;
            if (q.question_options) {
                q.question_options.forEach((opt: any) => {
                    if (opt.scenarios.includes(scenario) && opt.selected) {
                        if ((typeof(opt.alternate_group) === 'undefined') || opt.alternate_group === null) {
                            vals[0] = vals[0] + opt.value;
                        } else {
                            if (!vals[opt.alternate_group] || opt.value > vals[opt.alternate_group]) {
                                vals[opt.alternate_group] = opt.value;
                            }
                        }
                    }
                });
            }
            for (const key in vals) {
              rval += vals[key];
            }

            c.value = rval;
            return resolve(rval);
        });
    }

    // ** calculates max selected value for a question **//
    static calculateMaxActual = async (c: any, q: any, scenario: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            let rval = 0;
            if (q.question_options) {
                q.question_options.forEach((opt: any) => {
                    if (opt.scenarios.includes(scenario) && opt.selected) {
                        if (opt.value > rval) {
                            rval = opt.value;
                        }
                    }
                });
            }
            c.value = rval;
            return resolve(rval);
        });
    }

    static calculateSumMax = async (f: any, q: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let maxVal: any = 0;
            if (f.match(/SUM\(MAX\)/gi)) {
                Analyzer._traverse(q, (key, value, scope) => {
                    if (key === 'calculations') {
                        for (const ckey in value) {
                            if (value[ckey].code === 'MAX') {
                                maxVal = maxVal + value[ckey].value;
                            }
                        }
                    }
                });
            } else {
                maxVal = '';
            }
            return resolve(maxVal);
        });
    }

    static calculateSumActual = async (f: any, q: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            let maxVal: any = 0;
            let ready = true;
            Analyzer._traverse(q, (key, value, scope) => {
                if (key === 'calculations') {
                    for (const ckey in value) {
                        if (value[ckey].code === 'ACTUAL') { // sum of code actuals - not necessarily formula
                            if (value[ckey].value >= 0) {
                                maxVal = maxVal + value[ckey].value;
                            } else {
                                ready = false;
                            }
                        }
                    }
                }
            });

            if (!ready) {
                maxVal = '';
            }
            return resolve(maxVal);
        });
    }

    static loopCalculations = async (calculations: any, values: any): Promise<any> => {
        for (const key in calculations) {
            Analyzer.evaluateFormula(calculations[key], calculations, values);
        }
    }

    static evaluateFormula = async (calculation: any, calculations: any, values: any): Promise<any> => {
       let res;
// if (calculation.calc.uid == 'calc_32_1252') { console.log("calc", calculation)}
       let retVal: any;
       let f = calculation.calc.temp;
       const q = calculation.ref;

       f = await Analyzer.findCalculations(f, calculations, values);
       if (f.match(/SUM\(MAX\)/gi)) {
        res = await Analyzer.calculateSumMax(f, q);
        f = f.replace(/SUM\(MAX\)/gi, res);
        }
        // #{opt_UID}
       if (f.match(/opt[_\d]+/gi)) {
            const opts = f.match(/opt[_\d]+/gi);
            if (opts) {
                opts.forEach(oref => {
                    const oid = oref.split('_')[1];
                    // replace options with their values if selected
                    const os = q.question_options.filter(o => o.id == oid); // should only be one
                    const v = (os[0].selected) ? os[0].value : 0;
                    f = f.replace(`{${oref}}`, v);
                });
            }
        }

        // "IF(SCENARIO.IN(PK2,PB1,PA2,SB1,SR4,SR5,SS4,S4,S5,TS4,TS5,T4,T5))?(SUM(ACTUAL)):(SUM(ACTUAL)*{calc_LAYER_#{detect_multiplier}}*{calc_#{multipliers['SMMTP']}}*{calc_#{multipliers['TTMTP']}}*{calc_#{multipliers['STMTP']}} )").first_or_create

       if (f.match(/^IF\(/)) {
            // split if conditions
            const ifs = f.split('?');
            const ifCond = ifs[0];
            const ifRes = ifs[1].split(':');
            if (!ifCond.match(/calc[_\d]+/gi)) {
                const val = await Analyzer.validateCondition(ifCond);
                f = (val) ? ifRes[0] : ifRes[1];
            }
        } else if (f.match(/^CASE/)) {
            const dft = f.split('ELSE');
            const cond = dft[0].split('WHEN');
            const caseCompare = cond[0].substring(4).trim();
            let useThisCond = '';

            for (let i = 1; i < cond.length; i++) {
                const condSplit = cond[i].split('THEN');
                if (condSplit[0].match(/^\(IN/)) {
                  const cc = caseCompare.substring(1, caseCompare.lastIndexOf(')'));
                  const inS = condSplit[0].substring(4, condSplit[0].lastIndexOf(')') - 1).split(',');
                  if (inS.includes(cc)) {
                    useThisCond = condSplit[1];
                  }
                }else if (caseCompare === condSplit[0]) {
                    useThisCond = condSplit[1];
                }
            }
            if (useThisCond.length === 0) {
                useThisCond = dft[1];
            }
            f = useThisCond.trim();
        }

       if (f.match(/SUM\(ACTUAL\)/gi)) {
            res = await Analyzer.calculateSumActual(f, q);
            if (res !== '') {
                f = f.replace(/SUM\(ACTUAL\)/gi, res);
            }
        }
       if (!f.match(/calc[_\d]+/gi) && !f.match(/SUM\(ACTUAL\)/gi)) {
            retVal = await Analyzer.calculateFormula(f.trim());
            calculation.calc.value = retVal;
            calculation.calc.tvalue = retVal;
            values[calculation.calc.uid] = retVal;
            delete calculations[calculation.calc.uid];
        }
       calculation.calc.temp = f.trim();
       return retVal;
    }

    /** UIDS: #layer, if layer, then questionId then calculation_id */
    /** replaces {calc_id_id} with values from other formulas */
    static findCalculations = async (cond: string, calculations: any, values: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            const uids = cond.match(/calc[_\d]+/gi);
            if (uids) {
                uids.forEach(uid => {
                    if (typeof(values[uid]) === 'undefined') {
                        if (typeof(calculations[uid]) === 'undefined') {
                            cond = cond.replace(`{${uid}}`, '0');
                        }
                    } else {
                        if (values[uid] === 'N/A') {
                            cond = cond.replace(`{${uid}}`, '0');

                        } else {
                            cond = cond.replace(`{${uid}}`, values[uid]);
                        }
                    }
                });
            }
            return resolve(cond);
        });
    }

    static validateCondition = async (cond: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
          // strip if()
          if (cond.startsWith('IF')) {
              const lidxOfParen = cond.lastIndexOf(')');
              cond = cond.substring(3, cond.length - 1);
          }
          let sets = [];
          let countAll = true;
          // if or/and, eval minimal required
          if (cond.match(/or/)) {
              sets = cond.split('or');
              countAll = false;
          } else if (cond.match(/and/)) {
              sets = cond.split('and');
          } else {
              sets = [cond];
          }
          // assumes we're all numbers by now
          let weGood = false;
          let keepGoing = true;
          let parts: any;
          let partsCond: any;
          sets.every((elem: any) => {
            if (elem.match(/==/)) {
                parts = elem.trim().split('==');
                /** HACK .. (0*0) is  not yet evaluated. and this is specific. Need to make work for any formula */
                let p1 = parts[0].trim();
                if (parts[0].trim().match(/\*/)) {
                    p1 = eval(parts[0].trim());
                }
                partsCond = Number(p1) == Number(parts[1].trim());
            } else if (elem.match(/<=/)) {
                parts = elem.trim().split('<=');
                partsCond = Number(parts[0].trim()) <= Number(parts[1].trim());
            } else if (elem.match(/>=/)) {
                parts = elem.trim().split('>=');
                partsCond = Number(parts[0].trim()) >= Number(parts[1].trim());
            } else if (elem.match(/</)) {
                parts = elem.trim().split('<');
                partsCond = Number(parts[0].trim()) < Number(parts[1].trim());
            } else if (elem.match(/>/)) {
                parts = elem.trim().split('>');
                partsCond = Number(parts[0].trim()) > Number(parts[1].trim());
            } else if (elem.match(/IN/)) {
                parts = elem.trim().split('IN');
                const ar = parts[1].trim().substring(1, parts[1].lastIndexOf(')')).split(',');
                partsCond = ar.includes(parts[0].trim());
            }
            if (partsCond) {
                weGood = true;
                if (!countAll) {
                    keepGoing = false;
                }
            } else {
                if (countAll) {
                    weGood = false;
                    keepGoing = false;
                }
            }
            return keepGoing;
          });

          return resolve(weGood);
      });
    }

    // after removing all letters and references, final calculations
    static calculateFormula = (f: string, retVal?: boolean): any => {
        try {
            if (f.match(/^CUBE\(/)) {
                const str = f.substring(5, f.lastIndexOf(')'));
                return Math.cbrt(parseFloat(str));  //
            } else if (f.match(/^POW\(/)) {
                const str = f.substring(4, f.lastIndexOf(')'));
                const conds = str.split(',');
                return Math.pow(parseFloat(conds[0].trim()), parseFloat(conds[1].trim()));
            } else if (f.match(/^MAX\(/)) {
                f = f.replace(/^MAX/, 'Math.max');
                return eval(f);
            } else if (f.match(/^RISK\(/)) {
                const rsk = f.substring(5, f.lastIndexOf(')'));
                const conds = rsk.split(',');
                const val = conds[0];
                for (let i = 1; i < conds.length; i++) {
                    let cond = conds[i].trim().substring(1, conds[i].trim().lastIndexOf(']'));
                    cond = cond.replace(/x/g, val);
                    const cases = cond.split(':');
                    if (eval(cases[0])) {
                        return cases[1];
                    }
                }
            } else {

                // limit allowed chars for safety
                f = f.replace(/\r?\n|\r/g, ''); // get rid of line breaks
                if (f.match(/^[\(\d-+* /.\)]+$/))  {
                    return eval(f) || 0;
                } else {
                    return (retVal) ? f : null;
                }
            }
        } catch (error) {
            console.log(f);
            console.log(error);
            return null;
        }
    }
    /**
     * Traverses data json for matching key/val pair.
     * @param data
     * @param searchKey
     * @param searchVal
     * @returns
     */
    static checkNestedVals = (data: any, searchKey: string, searchVal: any): boolean => {
        let isFound = false;
        Analyzer._traverse(data, (key, value, scope) => {
            if (key === searchKey && value === searchVal) {
                isFound = true;
            }
        });
        return isFound;
    }

    /**
     * Loops data array to determine if any have selected: true
     * @param data
     * @returns
     */
    static checkSelectedVals = (data: any[]): boolean => {
        let isSelected = false;
        data.forEach(opt => {
            if (opt.selected) {
                isSelected = true;
            }
        });
        return isSelected;
    }


    /**
     *
     * @param data: answerJson
     * @param uid: uid of node to find
     * @returns
     */
    static getScope = async (data: any, uid: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            Analyzer._traverse(data, (key, value, scope) => {
                if (key === 'uid' && value === uid) {
                    return resolve(scope);
                }
            });
        });
    }


    /** traverses json keys - nesting ok  **/
    static async _traverse(obj: any, fn: (obj: any, prop: any, scope?: any) => void) {
        for (const key in obj) {
            fn.apply(obj, [key, obj[key], obj]);
            if (obj[key] !== null && typeof(obj[key]) === 'object') {
                this._traverse(obj[key], fn);
            }
        }
    }

}
