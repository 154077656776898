export class User {
    id: number;
    name: string;
    email: string;
    title: string;
    token: string;
    exp: Date;
    created_at: Date;
    site_role: string;
    status: string;
    organization: Organization;
    preferences: Preference;
    organizations: Organization[];
    last_login: Date;

    public get isAdmin(): boolean {
        return this.site_role === 'admin';
    }
}

export class Organization {
    id: number;
    name: string;
    code: string;
    role: string;    //specific to user ...
    created_at: Date;
    members: number;
    meta: any;
}

export class Preference {
    auto_save: boolean;
}

export enum RoleType {
    admin,
    reader,
    analyst
}

export enum UserStatus {
    confirmed,
    pending,
    cancelled
}
