import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AnswerService, ScenarioService } from '@app/_services';
import { Scenario, Answer } from '@app/_models';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'scenarios-list',
    templateUrl: './scenarios.component.html',
    styleUrls: ['../app.component.scss']
})
export class ScenariosComponent implements OnInit, OnDestroy {
    scenarios$: Observable<any>;
    selectedScenario: Scenario;

    sub: Subscription;
    scenarioSubscription: Subscription;
    showFirstScenario = false;
    @Input()
    set startFirst(val: boolean) {
        this.showFirstScenario = val;
    }

    constructor(private scenarioService: ScenarioService,
                private answerService: AnswerService) {

    }

    ngOnInit(): void {
        this.sub = this.answerService.answer.subscribe(res => {
            this.scenarios$ = this.scenarioService.getScenarios(res.survey.id);
        });

        this.scenarioSubscription = this.scenarioService.currentScenario.subscribe((value) => {
          this.selectedScenario = value;
        });

        if (this.showFirstScenario){
          this.scenarios$.subscribe(res => this.changeScenario(res[0]));
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
        this.scenarioSubscription.unsubscribe();
    }

    // scenario selected from dropdown
    changeScenario(s: Scenario): void {
      this.scenarioService.setScenario(s);
      this.selectedScenario = s;
    }

}
