import { Injectable } from '@angular/core';
import { Api } from './api.service';
import { Version } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(private api: Api) { }

  getVersions(): Observable<Version[]> {
    return this.api.get('versions');
  }
}
