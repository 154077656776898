import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AlertService, RuleService } from '@app/_services';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  email = '';
  constructor(private router: Router,
              private alertService: AlertService,
              private formBuilder: FormBuilder,
              private ruleService: RuleService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      isAdmin: ['', Validators.required],
      readRules: ['', Validators.required],
      agreeRules: ['', Validators.required]
    });
  }

  get f(): any { return this.form.controls; }

  onSubmit(): void {
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();
    this.setEmail();
    // stop here if form is invalid
    if (this.form.invalid) {
      this.router.navigate(['/behavior_rules']);
    }
    else{
      this.addRule();
      this.router.navigate(['/account/login']);
    }
  }

  setEmail(): void{
    this.activatedRoute.params.subscribe(params => {
      this.email = params.email;
    });
  }


  addRule(): void {
    this.ruleService.add(this.form.value, this.email).subscribe(result => {
      console.log('Rule added', result);
    });
  }
}
