import { Component, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { ChatService } from '@app/_services';
import {environment} from '@env/environment';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html'
})
export class ChatComponent implements OnInit {
    constructor(private sanitizer: DomSanitizer, private chatService: ChatService) {
    }
    apiURL = environment.apiUrl;
    safeURL: SafeResourceUrl;
    safeContent: SafeHtml;

    ngOnInit(): void {
      this.chatService.getChatContent(`${this.apiURL}chat_helpers`).subscribe(content => {
        this.safeContent = this.sanitizer.bypassSecurityTrustHtml(content);
      });
    }
}
