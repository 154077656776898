import { Component, OnInit } from '@angular/core';
import { Answer } from '@app/_models'
import { AnswerService } from '@app/_services';
import { Observable } from 'rxjs';

@Component({
    selector: 'answer-nav-bar',
    templateUrl: './answer-nav.component.html',
    styleUrls: ['../app.component.scss']
})
export class AnswerNavComponent implements OnInit {
    answer$: Observable<Answer>;

    constructor(private answerService: AnswerService) {
    }

    ngOnInit(): void {
        this.answer$ = this.answerService.answer;
    }


}
