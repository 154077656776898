import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';

import { AlertComponent } from './alert.component';
import { ScenariosComponent } from "./scenarios.component";
import { NotesComponent } from "./notes.component";
import { AnswerNavComponent } from "./answer-nav.component";


@NgModule({
    declarations: [AlertComponent, ScenariosComponent, NotesComponent, AnswerNavComponent],
    imports: [
        CommonModule, 
        FormsModule, 
        MatIconModule, 
        MatButtonModule, 
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule
    ],
    exports: [AlertComponent, ScenariosComponent, NotesComponent, AnswerNavComponent]
})
export class SharedModule { }